import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import dateFormat from "dateformat";
import { Container, Row, Col, Card, CardBody } from "shards-react";

import PageTitle from "../components/common/PageTitle";

const Danhsach = ({ history }) => {
  const [danhsach, setDanhsach] = useState()
  useEffect(() => {
    const thongtin = JSON.parse(localStorage.getItem('dangky'))
    if (thongtin) {
      if (thongtin.length) {
        // kiem tra xem con gia tri khong
        // return <Redirect to="/danh-sach" />
        const idList = thongtin.map(item => item.id)
        fetch(`${process.env.REACT_APP_API_URL}kiemtra`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            thongtin: idList
          }),
        }).then(response => response.json()).then(result => {
          if (result.data) {
            console.log(result.data);
            let tmp = []
            thongtin.map(item => {
              if (result.data.includes(item.id)) {
                tmp.push(item)
              }
            })
            if (tmp.length==1){
              history.replace(`/chi-tiet/${tmp[0].id}`)
            } else if (tmp.length==0) {
              history.replace(`/dang-ky`)
            }
            setDanhsach(tmp)
          } else {
            localStorage.setItem('dangky', JSON.stringify([]))
            history.replace(`/dang-ky`)
          }
        })
      } else {
        history.replace(`/dang-ky`)
      }
    } else {
      history.replace(`/dang-ky`)
    }
  }, [])
  return <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="12" title="Danh sách đã đăng ký" className="text-md-center" />
    </Row>

    <Row>
      <Col className="text-center mb-2"><NavLink to="/dang-ky"><h4>Đăng ký mới</h4></NavLink></Col>
    </Row>

    <Row>
      <Col>
        <Card small className="mb-4">
          <CardBody>
            <div className="table-responsive">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Họ tên
                  </th>
                  <th scope="col" className="border-0">
                    CMND
                  </th>
                  <th scope="col" className="border-0">
                    Ngày đăng ký
                  </th>
                  <th scope="col" className="border-0">

                  </th>
                </tr>
              </thead>
              <tbody>
                {danhsach ? danhsach.map((item, index) => <tr key={index}>
                  <td className="align-middle">{index + 1}</td>
                  <td className="align-middle"><NavLink to={`/chi-tiet/${item.id}`}><strong>{item.thongtin.hoten}</strong></NavLink></td>
                  <td className="align-middle">{item.thongtin.cmnd}</td>
                  <td className="align-middle">{dateFormat(item.ngaydangky, 'HH:MM, dd/mm/yyyy')}</td>
                  <td><NavLink to={`/chi-tiet/${item.id}`} className="btn btn-outline-primary btn-block">Chi tiết</NavLink></td>
                </tr>) : null}
              </tbody>
            </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
};

export default Danhsach;
