import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import Danhsach from "./views/Danhsach";
import UserProfileLite from "./views/UserProfileLite";
import Dangky from "./views/Dangky";
// import Errors from "./views/Errors";
// import ComponentsOverview from "./views/ComponentsOverview";
// import Tables from "./views/Tables";
// import BlogPosts from "./views/BlogPosts";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    // component: () => <Redirect to="/blog-overview" />
    // component: () => <Redirect to="/dang-ky" />
    component: () => {
      const thongtin = JSON.parse(localStorage.getItem('dangky'))
      if (thongtin) {
        // da dang ky, kiem tra con gia tri khong
        if (thongtin.length) {
          // kiem tra xem con gia tri khong
          return <Redirect to="/danh-sach" />
        } else {
          return <Redirect to="/dang-ky" />
        }
      } else {
        return <Redirect to="/dang-ky" />
      }
    }
  },
  {
    path: "/danh-sach",
    layout: DefaultLayout,
    component: Danhsach
  },
  {
    path: "/chi-tiet/:id",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/dang-ky",
    layout: DefaultLayout,
    component: Dangky
  },
  // {
  //   path: "/errors",
  //   layout: DefaultLayout,
  //   component: Errors
  // },
  // {
  //   path: "/components-overview",
  //   layout: DefaultLayout,
  //   component: ComponentsOverview
  // },
  // {
  //   path: "/tables",
  //   layout: DefaultLayout,
  //   component: Tables
  // },
  // {
  //   path: "/blog-posts",
  //   layout: DefaultLayout,
  //   component: BlogPosts
  // }
];
