import React, { useEffect, useState, useRef } from "react";
import { Modal, Spinner } from 'react-bootstrap'
import { NavLink } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha"
import Scanner from "react-webcam-qr-scanner"
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
// import socketIOClient from "socket.io-client";

import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";

// const host = "http://localhost:4000";
// const host = 'https://socketio.xetnghiemtaman.com';

const UserProfileLite = (props) => {
  // const socketRef = useRef()
  const [dulieu, setDulieu] = useState()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const id = props.match.params.id
    const thongtin = JSON.parse(localStorage.getItem('dangky'))
    let tontai = false
    if (thongtin) {
      thongtin.map((item, index) => {
        if (item.id == id) {
          setDulieu(item)
          tontai = true
        }
      })
    }
    if (!tontai) {
      props.history.replace('/')
    }
  }, [])
  // useEffect(() => {
  //   socketRef.current = socketIOClient.connect(host)
  //   // socketRef.current.on('sendDataServer', data => {
  //   //   console.log(data);
  //   //   // setLoading(false)
  //   // })
  //   socketRef.current.on('daxacnhan', data => {
  //     if (data.id==props.match.params.id) {
  //       console.log(data);
  //       // redirect to thanks
  //     }
  //   })
  //   socketRef.current.on('getId', data => {
  //     console.log(data);
  //   })
  //   return () => {
  //     socketRef.current.disconnect()
  //   }
  // }, [])
  const handleDecode = (result) => {
    if (result && result.data && result.data=='taman') {
      if (!loading) {
        setLoading(true)
        const msg = {
          id: props.match.params.id,
          data: result.data
        }
        // socketRef.current.emit('khaibao', msg)
      }
    }
  }
  const handleScannerLoad = (mode) => {
    // alert(mode)
  }
  return <React.Fragment>
    <Modal className="loadingModal" show={loading} onHide={e => setLoading(false)} backdrop="static" keyboard={false}>
      <Modal.Body>
        <Spinner animation="border" role="status" />
      </Modal.Body>
    </Modal>
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Xác thực với thu ngân" md="12" className="text-md-center" />
      </Row>
      <Row>
        <Col className="text-center mb-2"><NavLink to="/dang-ky"><h4>Đăng ký mới</h4></NavLink></Col>
      </Row>
      {dulieu ? <Row>
        <Col lg="4">
          <UserDetails userDetails={dulieu} />
        </Col>
        <Col lg="8">
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Hướng camera với mã QR tại thu ngân</h6>
            </CardHeader>
            <CardBody>
              <Scanner
                className="qr-scanner"
                onDecode={handleDecode}
                onScannerLoad={handleScannerLoad}
                constraints={{
                  audio: false,
                  video: {
                    facingMode: { exact: "environment" },
                    width: { ideal: 1280 },
                    height: { ideal: 1024 }
                  }
                }}
                captureSize={{ width: 1280, height: 1024 }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row> : null}
    </Container>
  </React.Fragment>
};

export default UserProfileLite;
